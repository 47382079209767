@import '~@angular/material/theming';
@import 'src/app/shared/styles/variables';
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500');

$blue-altine: (
  main: #1c355e,
  lighter: #bbc2cf,
  darker: #112242,
  200: #1c355e,
  // For slide toggle,
  contrast : (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

$mat-accent: (
  main: #ec681d,
  lighter: #f9d2bb,
  darker: #e44b11,
  200: #ec681d,
  // For slide toggle,
  contrast : (main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

$mat-warn: (
  main: #b65b9d,
  lighter: #e9cee2,
  darker: #9e4081,
  200: #b65b9d,
  // For slide toggle,
  contrast : (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

//App fonts
@font-face {
  font-family: 'Nobel';
  font-style: normal;
  font-weight: 400;
  src: local('Nobel'), local('Nobel-Regular'),
    url('/assets/fonts/nobel/nobel-regular.woff') format('woff');
  font-display: swap;
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Nobel Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Nobel Bold'), local('Nobel-Bold'),
    url('/assets/fonts/nobel/nobel-bold.woff') format('woff');
  font-display: swap;
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Nobel Book';
  font-style: normal;
  font-weight: normal;
  src: local('Nobel Book'), url('/assets/fonts/nobel/nobel-book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Nobel Light';
  font-style: normal;
  font-weight: 400;
  src: local('Nobel Light'), local('Nobel-Light'),
    url('/assets/fonts/nobel/nobel-light.woff') format('woff');
  font-display: swap;
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Nobel Black';
  font-style: normal;
  font-weight: 800;
  src: local('Nobel Black'), local('Nobel-Black'),
    url('/assets/fonts/nobel/nobel-black.woff') format('woff');
  font-display: swap;
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Nobel Black';
  font-style: normal;
  font-weight: 800;
  src: local('Nobel Black'), local('Nobel-Black');
  font-display: swap;
}

@font-face {
  font-family: 'Nobel Regular';
  font-style: normal;
  font-weight: 800;
  src: local('Nobel Regular'), local('Nobel-Regular'),
    url('/assets/fonts/nobel/nobel-regular.woff') format('woff');
  font-display: swap;
}

$general-typography: mat-typography-config($font-family: '"Nobel Book", "Helvetica Neue", sans-serif',
    $body-2: mat-typography-level(16px, 25px, 600));

//Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
@include mat-core($general-typography);
//@include mat-core();

$primary: mat-palette($blue-altine, main);
$accent: mat-palette($mat-accent);
$warn: mat-palette($mat-warn);
//$background: mat-palette($mat-deep-purple, 300);
$variant: mat-palette($mat-yellow, 300);
$theme: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($theme);
$app-dark-theme: mat-dark-theme($primary, $accent, $warn);

.darkMode {
  @include angular-material-theme($app-dark-theme);
}

// html, body { height: 100%}
body {
  margin: auto;
  font-family: 'Nobel Book', "Helvetica Neue", sans-serif !important;
  color: $blue;
}

.login-dialog .mat-dialog-container,
.signup-dialog .mat-dialog-container {
  padding: 0 !important;
  border-radius: $size16;
}

mat-dialog-container {
  padding: 0 !important;
}

.mat-dialog-container {
  padding: 0 !important;
}

.dialog-container {
  padding: 0 !important;
}

.padding-dialog-0 {
  mat-dialog-container {
    padding: 0 !important;
  }

}

.mat-icon {
  fill: $blue;
  // stroke: $blue;
}
